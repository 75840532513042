<script lang="ts">import { loading } from '../stores/stores';
import { fade } from 'svelte/transition';
</script>

{#if $loading}
  <div transition:fade>
    <div class="bg" />
    <div class="window">
      <div class="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
{/if}

<style>
  div {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: 99;

    display: grid;
    justify-content: center;
    align-content: center;
  }

  .window {
    color: var(--color-white);
    padding: 50px;
    box-sizing: border-box;
    text-align: center;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-primary-dark);
    opacity: 0.85;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

</style>
