<script lang="ts">import { worldSettingsVisible, biomechanicsIsExpanded, } from '../../../../stores/stores';
import { trial } from '../../../../stores/trial';
import { resize } from '../../../../utils/resize';
import TimeSeriesChart from './TimeSeriesChart.svelte';
import XFactorChart from './golf/XFactorChart.svelte';
import SwayChart from './golf/SwayChart.svelte';
import KineticChart from './golf/KineticChart.svelte';
import Metrics from './Metrics.svelte';
import Snapshots from './snapshots/Snapshots.svelte';
import IconButton from '../../ui/IconButton.svelte';
import TabBar from '../../ui/TabBar.svelte';
import { fly } from 'svelte/transition';
import SequenceChart from './SequenceChart.svelte';
export let data;
let biomechPanel;
let biomechOpen = false;
let chart;
let selected = $trial.defaultTab;
function handleResize(e) {
    if (e.detail.x >= 700) {
        biomechPanel.style.width = `${e.detail.x}px`;
    }
    if (chart) {
        chart.handleResize();
    }
}
$: {
    if (!$trial.supportsTab(selected)) {
        selected = $trial.defaultTab;
    }
}
</script>

{#if biomechOpen}
  <div
    bind:this={biomechPanel}
    transition:fly={{ x: -500, duration: 200 }}
    class={`biomech panel shadow ${$biomechanicsIsExpanded && 'biomechExpanded'}`}
    class:fade={$worldSettingsVisible}>
    <div class="wrapper">
      <div use:resize on:resize={handleResize} class="dragHandle" />
      <div class="nav">
        <h4 class="typography-h4">Biomechanics Data</h4>
        <IconButton on:click={() => (biomechOpen = false)} icon="close-max" />
      </div>
      <TabBar tabs={$trial.tabs} bind:selected />
      <div class="content">
        {#if selected == 'X-Factor'}
          <XFactorChart bind:parent={chart} {data} />
        {:else if selected == 'Sway'}
          <SwayChart {data} />
        {:else if selected == 'Kinetic Chain'}
          <KineticChart bind:this={chart} {data} />
        {:else if selected == 'Snapshots'}
          <Snapshots {data} />
        {:else if selected == 'Time Series'}
          <TimeSeriesChart bind:this={chart} {data} />
        {:else if selected == 'Sequence'}
          <SequenceChart bind:this={chart} {data} />
        {:else if selected == 'Metrics'}
          <Metrics {data} />
        {/if}
      </div>
    </div>
  </div>
{:else}
  <div class="biomechButton shadow" class:fade={$worldSettingsVisible}>
    <button on:click={() => (biomechOpen = true)}>Biomechanics Data</button>
  </div>
{/if}

<style>
  .biomech {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 50%;
    height: calc(100% - 20px);
    border-radius: 0px 8px 8px 0px;
    z-index: 1;
    border: 1px solid var(--color-gray-lighter);
    border-left: none;
  }

  .biomechExpanded {
    width: 95% !important;
    transition: 0.5s;
  }

  .biomechButton {
    position: absolute;
    top: 180px;
    left: 0px;
    height: auto;
    width: auto;
    transform: rotate(-90deg);
    transform-origin: top left;
    background: none;
    border-radius: 0px 0px 8px 8px;
  }

  .biomechButton button {
    height: 44px;
    margin: 0;
    padding: 0px 24px;
    border-radius: 0px 0px 8px 8px;
  }

  .dragHandle {
    position: absolute;
    top: 0;
    right: 0;
    width: 6px;
    height: 100%;
    cursor: ew-resize;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0px 8px 8px 0px;
  }

  .content {
    display: flex;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    padding: 16px;
  }

  .nav {
    display: flex;
    padding: 0px 12px 0px 16px;
    border-radius: 0px 8px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--color-gray-extralight);
  }

  .fade {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in;
  }

</style>
