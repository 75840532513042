<script lang="ts">;
import { generic } from '../../../../../models/selectors';
import { color } from '../../../../../utils/styles';
import Tooltip from '../../../ui/TooltipWithoutIcon.svelte';
export let data;
export let active;
export let onClick;
export let footerColor;
export let isComparison;
const STANCE = 'Stance';
const DEG = 'deg';
function getDisplayValue(data, active) {
    const value = data[active] || data[STANCE] || data.Value;
    return value === null || value === void 0 ? void 0 : value.toFixed(2);
}
$: displayValue = getDisplayValue(data, active);
</script>

<div
  class={`box-card ${isComparison && 'comparison-box'}`}
  style="--footer-color: {color(footerColor)}"
  on:click={onClick}>
  {#if !displayValue}
    <div>'N/A'</div>
  {:else if data.Units === DEG}
    <div class={`value ${isComparison && 'comparison-value'}`}>
      <p>{displayValue}<sup>°</sup></p>
    </div>
  {:else}
    <div class={`value ${isComparison && 'comparison-value'}`}>
      <p>{displayValue}</p>
      <span class={`unit-value ${isComparison && 'comparison-unit-value'}`}>
        {generic.abbreviatedUnit(data)}
      </span>
    </div>
  {/if}
  <div class="unit">
    <Tooltip title={data.Label}>
      <img
        class="icon"
        src={'/assets/icons/info-circle.svg'}
        alt="Circle info" />
    </Tooltip>
    <div class="line-clamp">{generic.abbreviatedLabel(data.Label)}</div>
  </div>
</div>

<style>
  .box-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-bottom: 15px solid var(--footer-color);
    text-align: center;
    border-radius: 5px;
    background-color: var(--color-gray-extralight);
    padding: 15px 5px;
    min-height: 170px;
    position: relative;
  }
  .box-card:hover {
    background-color: var(--color-gray-extralight);
    cursor: pointer;
  }
  .comparison-box {
    width: 45%;
    min-height: 0;
  }
  .value {
    font-weight: var(--typography-weight-bold);
    font-size: var(--typography-size-xbig);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .comparison-value {
    font-size: var(--typography-size-big);
  }
  .unit {
    font-weight: var(--typography-weight-bold);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--typography-size-smaller);
  }
  .unit-value {
    color: var(--color-gray-lighter);
    letter-spacing: 0.15em;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-size: var(--typography-size-regular);
    padding-right: 5px;
  }
  .comparison-unit-value {
    font-size: var(--typography-size-small);
  }
  .icon {
    transform: rotate(90deg);
    width: 20px;
    margin-right: 5px;
  }
  .line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: var(--typography-size-xsmall);
    font-weight: var(--typography-weight-regular);
    letter-spacing: 0.1em;
  }

</style>
