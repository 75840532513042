<script type="ts">import { isNil } from 'ramda';
;
import { getMetricByLabel, golf } from '../../../../../models/selectors';
import { comparisonTrialData } from '../../../../../stores/trial';
import Gauge from '../../../charts/Gauge.svelte';
import SpineRotation from '../../../charts/SpineRotation.svelte';
import InfoButton from '../../../ui/InfoButton.svelte';
export let data;
export let parent;
function metricToGaugeData(label, series) {
    if (isNil(series)) {
        return undefined;
    }
    const metric = getMetricByLabel(label, series);
    return { Data: metric.Value, Label: metric.Label };
}
function xFactorData(series) {
    return metricToGaugeData('X-Factor', series);
}
function xFactorStretchData(series) {
    return metricToGaugeData('X-Factor Stretch', series);
}
$: mainSeries = golf.discreteMetrics(data);
$: comparisonSeries = $comparisonTrialData
    ? golf.discreteMetrics($comparisonTrialData)
    : undefined;
</script>

<div class="x-factor-container">
  <div class="gauges-container">
    <div class="gauge-container">
      <div class="info-button-container left-info-button">
        <InfoButton
          class="info-button-size"
          iconClassName="info-icon-size"
          text="Rotation of the shoulders in relation to the hips" />
      </div>
      <Gauge
        steps={[0, 10, 20, 30, 40]}
        data={{ ...xFactorData(mainSeries), Average: 30 }}
        comparisonData={xFactorData(comparisonSeries)} />
    </div>

    <div class="gauge-container">
      <div class="info-button-container right-info-button">
        <InfoButton
          class="info-button-size"
          iconClassName="info-icon-size"
          text="Increase in the X-Factor due to the hips starting down before the shoulders" />
      </div>
      <Gauge
        steps={[0, 5, 10, 15, 20]}
        data={{ ...xFactorStretchData(mainSeries), Average: 15 }}
        comparisonData={xFactorStretchData(comparisonSeries)} />
    </div>
  </div>

  <div class="spine-rotation-container">
    <InfoButton
      text="Spine rotation information"
      class="spine-chart-info-button info-button-size"
      tooltipClassName="spine-chart-tooltip"
      iconClassName="spine-chart-info-icon info-icon-size" />
    <SpineRotation
      bind:this={parent}
      {data}
      comparisonData={$comparisonTrialData} />
  </div>
</div>

<style>
  .x-factor-container {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 100%;
    z-index: 600;
  }

  .gauges-container {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }

  .gauge-container {
    display: flex;
    flex-direction: row;
  }

  .spine-rotation-container {
    margin-top: 20px;
  }

  :global(.info-button-size) {
    height: 20px !important;
    width: 20px !important;
  }

  :global(.info-icon-size) {
    height: 20px !important;
    width: 20px !important;
  }

  :global(.spine-chart-info-button) {
    position: absolute;
    margin-top: 90px !important;
    margin-left: 10px !important;
  }

  :global(.spine-chart-info-icon) {
    transform: rotate(-90deg);
  }

  .info-button-container {
    width: 95px;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1;
    margin-top: 12px;
  }

  .left-info-button {
    margin-left: 210px;
  }

  .right-info-button {
    margin-left: 240px;
  }

</style>
