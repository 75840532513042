<script lang="ts">export let active;
export let tabsNames;
export let handleChange;
</script>

<div class="container">
  {#each tabsNames as tab, index}
    <div on:click={() => handleChange(tab)}>
      <p class={active === tab ? 'selected' : 'normal'}>{tab}</p>
      {#if active === tab}
        <div class="line" />
      {/if}
    </div>
  {/each}
</div>

<style>
  .container {
    display: flex;
    justify-content: space-around;
  }
  .selected {
    color: var(--color-black);
    margin-bottom: 1px;
  }
  .line {
    border: 3px solid var(--color-primary-dark);
    border-radius: 10px;
  }
  .normal {
    color: var(--color-primary-main);
  }
  .normal:hover {
    cursor: pointer;
  }

</style>
