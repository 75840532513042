<script type="ts">import { zip, length, mapObjIndexed, values, compose, isNil, unless, } from 'ramda';
import { onDestroy } from 'svelte';
import { discreteMetrics, biomechanicsIsExpanded, metricIndices, } from '../../../../stores/stores';
import { trial, golfTrial, comparisonTrialData, comparisonTrial, } from '../../../../stores/trial';
import { average } from '../../../../utils/math';
import { getDiscreteMetrics } from '../../../../utils/metrics';
import MetricTableCell from './components/MetricTableCell.svelte';
;
;
import LegendContainer from '../../ui/LegendContainer.svelte';
export let data;
let isExpanded = false;
const METRICS_KEYS = $golfTrial.metricKeys;
const addLabel = (value, key) => (Object.assign({ label: key }, value));
const discreteMetricsToArray = compose(values, mapObjIndexed(addLabel));
$: mainMetrics = discreteMetricsToArray($discreteMetrics);
$: comparisonMetrics = $comparisonTrialData
    ? discreteMetricsToArray(getDiscreteMetrics($comparisonTrialData))
    : undefined;
$: allMetrics = zip(mainMetrics, comparisonMetrics !== null && comparisonMetrics !== void 0 ? comparisonMetrics : new Array(length(mainMetrics)));
function changeSizeOfBioMechanics() {
    biomechanicsIsExpanded.set(!$biomechanicsIsExpanded);
    isExpanded = !isExpanded;
}
function getKeyframeValue(data, metricName, keyframeName) {
    const left = $metricIndices[`Left ${metricName}`];
    const right = $metricIndices[`Right ${metricName}`];
    const plain = $metricIndices[metricName];
    const getMetric = ({ i, j, k }) => data.bma_metrics[i].Series[j].Series[k];
    return left && right
        ? {
            Left: getMetric(left)[keyframeName],
            Right: getMetric(right)[keyframeName],
        }
        : getMetric(plain)[keyframeName];
}
function min(metric) {
    return { left: metric.MinLeft, right: metric.MinRight, value: metric.Min };
}
function max(metric) {
    return { left: metric.MaxLeft, right: metric.MaxRight, value: metric.Max };
}
function avg(metric) {
    return {
        left: average(metric.MinLeft, metric.MaxLeft),
        right: average(metric.MinRight, metric.MaxRight),
        value: average(metric.Max, metric.Min),
    };
}
function keyframe(data, metricName, keyframeName) {
    if (isNil(data)) {
        return undefined;
    }
    return {
        left: getKeyframeValue(data, metricName, keyframeName).Left,
        right: getKeyframeValue(data, metricName, keyframeName).Right,
        value: getKeyframeValue(data, metricName, keyframeName),
    };
}
onDestroy(() => {
    biomechanicsIsExpanded.set(false);
});
</script>

<div class="container">
  <div>
    <table style="width: 100%">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Min</th>
          <th>Max</th>
          <th>Average</th>
          {#if $trial.hasKeyFrames && isExpanded}
            <th>Address</th>
            <th>Mid-Backswing</th>
            <th>Peak Backswing</th>
            <th>Mid-Downswing</th>
            <th>Impact</th>
            <th>Follow-though</th>
          {/if}
        </tr>
      </thead>
      <tbody>
        {#each allMetrics as [mainMetric, comparisonMetric]}
          <tr class="row-hover">
            <MetricTableCell title={mainMetric.label} />

            <MetricTableCell
              main={min(mainMetric)}
              comparison={unless(isNil, min, comparisonMetric)} />

            <MetricTableCell
              main={max(mainMetric)}
              comparison={unless(isNil, max, comparisonMetric)} />

            <MetricTableCell
              main={avg(mainMetric)}
              comparison={unless(isNil, avg, comparisonMetric)}
              border />

            {#if $trial.hasKeyFrames && isExpanded}
              {#each METRICS_KEYS as keyframeName}
                <MetricTableCell
                  main={keyframe(data, mainMetric.label, keyframeName)}
                  comparison={keyframe($comparisonTrialData, comparisonMetric?.label, keyframeName)} />
              {/each}
            {/if}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
<div class={`footerExpand ${$comparisonTrial && 'footer-with-compare'}`}>
  {#if $comparisonTrial}
    <div style="width: 60%">
      <LegendContainer
        firstTrialName={$trial.displayName}
        secondTrialName={$comparisonTrial.displayName} />
    </div>
  {/if}
  <button on:click={changeSizeOfBioMechanics} class="primary">
    {#if isExpanded}
      <img src={'assets/icons/minimize-arrows.svg'} alt="close button" />
    {:else}
      <img src={'assets/icons/expand-arrows.svg'} alt="expand button" />
    {/if}
    <span
      class="textButton">{isExpanded ? 'Minimize Table' : 'Expand Table'}</span>
  </button>
</div>

<style>
  .container {
    margin: -16px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 99%;
  }
  .textButton {
    margin-left: 5px;
  }

  .footerExpand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
  .footer-with-compare {
    justify-content: space-between;
  }

  .row-hover:hover {
    background-color: var(--color-gray-extralight);
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    padding-bottom: 16px;
  }

  th {
    text-align: left;
    position: sticky;
    top: 0;
    padding: 8px 16px 8px 16px;
    background: var(--color-gray-extralight);
    border: 0px solid var(--color-gray-lighter);
    border-bottom: 2px solid var(--color-gray-lighter);
  }
  tr:nth-of-type(even) {
    background: var(--color-gray-extralight);
  }

</style>
