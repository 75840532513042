<script lang="ts">import DatePicker from './ui/datepicker/DatePicker.svelte';
import Dropdown from './ui/Dropdown.svelte';
import IconButton from './ui/IconButton.svelte';
import TrialSelector from './actionbar/TrialSelector.svelte';
import WorldView from './views/WorldView.svelte';
import Playback from './playback/Playback.svelte';
import Biomechanics from './panels/biomechanics/Biomechanics.svelte';
import LiveMetrics from './panels/livemetrics/LiveMetrics.svelte';
import Videos from './panels/videos/Videos.svelte';
import { createEventDispatcher } from 'svelte';
import { dateMap, trials, asenseiFiles } from '../../stores/stores';
import Asensei from './panels/asensei/Asensei.svelte';
export let data;
export const MARGIN = 10;
let views;
let selectedTrial;
let selectedTrialKey;
let trialComparisonVisible = false;
let metricOffsetY = 186;
let selectedDate = null;
let selectedTrials;
let userMenuItems = [{ text: 'Logout' }];
let asenseiFile = null;
let asenseiTabIsVisible = false;
$: {
    if (selectedDate) {
        let datestring = ('0' + (selectedDate.getMonth() + 1).toString()).slice(-2) +
            '-' +
            ('0' + selectedDate.getDate().toString()).slice(-2) +
            '-' +
            selectedDate.getFullYear().toString();
        selectedTrials = $dateMap.get(datestring);
    }
    else {
        selectedTrials = $trials;
    }
}
$: {
    const currentTrialKey = selectedTrialKey || ($trials && $trials[0] && $trials[0].originalKey);
    asenseiFile = $asenseiFiles.get(currentTrialKey);
    asenseiTabIsVisible = !!asenseiFile;
}
const dispatch = createEventDispatcher();
function onSelect(detail) {
    var _a, _b, _c, _d;
    selectedTrial = (_b = (_a = detail === null || detail === void 0 ? void 0 : detail.item) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.displayName;
    selectedTrialKey = (_d = (_c = detail === null || detail === void 0 ? void 0 : detail.item) === null || _c === void 0 ? void 0 : _c.value) === null || _d === void 0 ? void 0 : _d.originalKey;
}
function handleDateSelection(e) {
    selectedDate = e.detail.selected;
}
const handleTrialSelection = (name) => (e) => {
    dispatch(name, { item: e.detail.item.value });
};
function handleVideoResize(e) {
    metricOffsetY = e.detail.top + e.detail.height + 12;
}
function handleUserMenuSelection(e) {
    if (e.detail.item.text == 'Logout') {
        dispatch('logout');
    }
}
function handleCompare() {
    trialComparisonVisible = !trialComparisonVisible;
}
</script>

<section class="dashboard">
  <div class="views" bind:this={views}>
    <div class="topNav">
      <DatePicker on:select={handleDateSelection} />
      <TrialSelector
        {selectedTrials}
        on:select={handleTrialSelection('mainTrialSelected')}
        on:trialComparisonStart={handleCompare}
        {onSelect}
        isMainTrial={true} />
      {#if trialComparisonVisible}
        <span class="vs-text">VS</span>
        <DatePicker on:select={handleDateSelection} />
        <TrialSelector
          {selectedTrials}
          on:select={handleTrialSelection('comparisonTrialSelected')}
          on:removeComparisonTrial
          on:removeComparisonTrial={handleCompare}
          {onSelect}
          isMainTrial={false} />
        <!-- The code above both handles and forwards the removeComparisonTrial event. 😏 -->
      {/if}
      <span class="user">
        <Dropdown on:select={handleUserMenuSelection} items={userMenuItems}>
          <span slot="toggle"><IconButton icon="user" /></span>
        </Dropdown>
      </span>
    </div>
    <WorldView {data} />
    <Biomechanics {data} />
    <Videos {data} on:resize={handleVideoResize} />
    <LiveMetrics {data} {asenseiFile} {metricOffsetY} />
    {#key asenseiFile}
      {#if !!asenseiTabIsVisible}
        <Asensei {data} {asenseiFile} metricOffsetY={metricOffsetY + 143} />
      {/if}
    {/key}
  </div>
  <Playback />
</section>

<style>
  .dashboard {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .views {
    display: flex;
    height: 100%;
    position: relative;
    background-color: var(--color-gray-lighter);
    overflow: hidden;
  }

  .vs-text {
    font-size: var(--typography-size-big);
    padding: 2px 10px;
    font-weight: var(--typography-weight-bold);
    color: var(--color-primary-main);
  }

  .topNav {
    position: absolute;
    overflow: visible;
    display: flex;
    top: 20px;
    right: 10px;
    height: auto;
    width: auto;
    z-index: 1;
    background: none;
  }

  :global(.views > *) {
    position: relative;
    background-color: var(--color-white);
    overflow: hidden;
    display: block;
    width: calc(100% - 1px);
    height: calc(100% - 1px);
  }

  .user {
    margin-left: 12px;
  }

</style>
