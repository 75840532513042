<script type="ts">import { last } from 'ramda';
import { highcharts } from '../../../utils/highcharts';
import { color, typography } from '../../../utils/styles';
import { chartSeriesColors } from '../../../utils/charts';
export let data;
export let comparisonData = null;
export let steps;
export let unit = '°';
export let outterUnit = '';
export let unitStyle = '';
export let orientation = 'horizontal';
const colors = chartSeriesColors();
const datasContainer = `font-size: ${typography.size('bigger')}`;
const verticalDatasContainer = `font-size: ${typography.size('big')};`;
const mainColor = `color:${colors[0]};`;
const comparisonColor = `color:${colors[1]};`;
const dataDivider = 'color: var(--color-gray-lighter);';
$: horizontalOrientation = `<span style="${datasContainer}"><span style="${mainColor}">{y:.0f}</span><span style="${mainColor} ${unitStyle}">${unit}</span>${comparisonData
    ? `<span style="${dataDivider}"> / </span><span style="${comparisonColor}">${`${comparisonData.Data}`.split('.')[0]}</span><span style="${comparisonColor} ${unitStyle}">${unit}</span>`
    : ''}</span>`;
$: verticalOrientation = `<span style="${verticalDatasContainer}"><span style="${mainColor}">{y:.0f}${!comparisonData
    ? `<span style="${mainColor} ${unitStyle}">${unit}</span>`
    : ''}</span>${comparisonData
    ? `<span style="${dataDivider}"> / </span><span style="${comparisonColor}">${`${comparisonData.Data}`.split('.')[0]}</span><span style="${comparisonColor} ${unitStyle}"></span>`
    : ''}</span>`;
$: seriesDataLabelsFormat =
    orientation === 'horizontal' ? horizontalOrientation : verticalOrientation;
$: handledData = {
    type: 'solidgauge',
    name: data.Label,
    data: [
        {
            name: data.Label,
            color: colors[0],
            radius: '112%',
            innerRadius: '88%',
            y: data.Data,
        },
    ].concat(comparisonData
        ? {
            name: data.Label,
            color: colors[1],
            radius: '112%',
            innerRadius: '88%',
            y: comparisonData.Data,
        }
        : []),
};
$: sortedData = [
    Object.assign(Object.assign({}, handledData), { data: handledData.data.sort((a, b) => b.y - a.y) }),
];
$: config = {
    chart: {
        type: 'solidgauge',
    },
    title: {
        text: orientation === 'vertical' && comparisonData
            ? `${data.Label} (${unit})`
            : data.Label,
        margin: 30,
        style: {
            fontWeight: `${typography.weight('bold')}`,
            fontSize: `${typography.size(orientation === 'vertical' && comparisonData ? 'smaller' : 'small')}`,
            fontFamily: `${typography.family()}`,
            margin: '45px',
        },
    },
    credits: { enabled: false },
    tooltip: { enabled: false },
    pane: {
        startAngle: -90,
        endAngle: 90,
        background: [
            {
                outerRadius: '112%',
                innerRadius: '88%',
                backgroundColor: color('gray-extralight'),
                shape: 'arc',
            },
        ],
    },
    yAxis: {
        min: 0,
        max: last(steps),
        minorTickInterval: null,
        lineWidth: 0,
        tickWidth: 0,
        tickPositions: steps,
        labels: {
            format: `{text}${outterUnit ? outterUnit : unit}`,
            distance: 30,
        },
        plotBands: [
            {
                to: data.Average,
                from: data.Average + (last(steps) * 1.8) / 100,
                color: color('accent-main'),
                zIndex: 20,
                thickness: 27,
                innerRadius: 84,
                outerRadius: 107,
            },
        ],
    },
    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: -30,
                borderWidth: 0,
            },
        },
        series: {
            dataLabels: {
                format: seriesDataLabelsFormat,
            },
        },
    },
    series: sortedData,
};
</script>

<div class="gauge">
  <div
    id="container"
    use:highcharts={config}
    style="width: 300px; height: 300px;" />

  <div class="gauge-legend-container"><span class="legend-tag" /> Tour avg</div>
</div>

<style>
  .gauge {
    display: flex;
    width: 100%;
    height: 240px;
    position: relative;
  }

  .gauge :global(.info-button) {
    background-color: var(--color-white);
    margin-top: 15px;
    margin-left: 40%;
  }

  .gauge-legend-container {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 10px;
  }
  .legend-tag {
    height: 5px;
    width: 28px;
    background-color: var(--color-accent-main);
    margin-right: 5px;
  }

</style>
