<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ErrorRecordings from './components/ErrorRecordings.svelte';
import Dashboard from './components/dashboard/Dashboard.svelte';
import Authentication from './components/Authentication.svelte';
import PasswordReset from './components/PasswordReset.svelte';
import LoaderOverlay from './components/LoaderOverlay.svelte';
import InactivityTimer from './components/InactivityTimer.svelte';
import { NotificationDisplay, notifier } from '@beyonk/svelte-notifications';
import { credentials, trials, dateMap, trialMap, loading, asenseiFiles } from './stores/stores';
import { comparisonTrial, trial } from './stores/trial';
import { login, logout, forgotPassword, setPassword } from './aws/auth';
import { fetchAsenseiFileIfPresent, fetchTrialData } from './aws/fetch';
;
import { fetchRecordings } from './aws/list';
;
import { onMount } from 'svelte';
import { isDebugMode } from './stores/debug';
import { Trial } from './models/trial';
let items = [];
let data;
let loginError = false;
let pwResetError = false;
let errorMsg = '';
let recordingError = false;
let codeSent = false;
let resettingPassword = false;
function loadTrial(item, options) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetchTrialData(item, options);
    });
}
function createComparisonTrial(recording) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield loadTrial(recording, { isMainTrial: false });
        return Trial.withMainTrial(data, recording, $trial);
    });
}
function initializeTrials() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            items = yield fetchRecordings();
            // add trials to a store so we can access them easily in the trial selector
            trials.set(items);
            items.forEach((item) => {
                fetchAsenseiFileIfPresent(item)
                    .then(asenseiFile => {
                    if (asenseiFile) {
                        $asenseiFiles.set(item.originalKey, asenseiFile);
                    }
                });
                // add trials to dateMap (hashmap of date strings / list of trials), so we can get trials by date in the datepicker
                if ($dateMap.has(item.dateString)) {
                    $dateMap.get(item.dateString).push(item);
                }
                else {
                    $dateMap.set(item.dateString, [item]);
                }
                // add trials to trialMap (hashmap of id / trial), so we can get selectedTrial by id
                $trialMap.set(item.id, item);
            });
            data = yield loadTrial(items[0], { isMainTrial: true });
        }
        catch (e) {
            items = [];
            notifier.danger('Could not fetch recordings');
            console.error(`Failed recordings fetch`, e);
            recordingError = true;
        }
        loading.set(false);
    });
}
function handleMainTrialSelected({ detail }) {
    return __awaiter(this, void 0, void 0, function* () {
        loading.set(true);
        try {
            data = yield loadTrial(detail.item, { isMainTrial: true });
        }
        catch (e) {
            notifier.danger('Could not fetch recording');
            console.error(`Failed downloading`, e);
            recordingError = true;
        }
        loading.set(false);
    });
}
function handleComparisonTrialSelected({ detail }) {
    return __awaiter(this, void 0, void 0, function* () {
        loading.set(true);
        const trial = yield createComparisonTrial(detail.item);
        if (trial.supportsComparison) {
            comparisonTrial.set(yield createComparisonTrial(detail.item));
        }
        else {
            notifier.danger(`Discipline ${trial.discipline} doesn't support comparison. Please choose another trial.`);
        }
        loading.set(false);
    });
}
function removeComparisonTrial() {
    loading.set(true);
    comparisonTrial.set(undefined);
    loading.set(false);
}
function onLogin(event) {
    return __awaiter(this, void 0, void 0, function* () {
        loading.set(true);
        const { username, password } = event.detail;
        if (username && password) {
            try {
                yield login(username, password);
                loginError = false;
                initializeTrials();
            }
            catch (e) {
                console.error(`Failed to login`, e);
                notifier.danger('Login Failed');
                loading.set(false);
            }
        }
        else {
            loginError = true;
            notifier.warning(`Missing username/password`);
            loading.set(false);
        }
    });
}
function onRequestCode(event) {
    return __awaiter(this, void 0, void 0, function* () {
        const { username } = event.detail;
        if (username) {
            try {
                yield forgotPassword(username);
                pwResetError = false;
                codeSent = true;
            }
            catch (e) {
                console.error('Failed to request password reset code', e);
                errorMsg = e.message;
                notifier.danger('Password code request failed.');
            }
        }
        else {
            pwResetError = true;
            notifier.warning('Missing username');
        }
    });
}
function onSetPassword(event) {
    return __awaiter(this, void 0, void 0, function* () {
        const { username, code, password } = event.detail;
        if (username && code && password) {
            try {
                yield setPassword(username, code, password);
                pwResetError = false;
                resettingPassword = false;
                notifier.success('Password reset.');
            }
            catch (e) {
                console.error('Password reset failed', e);
                errorMsg = e.message;
                notifier.danger('Password reset failed.');
            }
        }
        else {
            pwResetError = true;
            notifier.warning('Missing verification code or password');
        }
    });
}
function startReset() {
    errorMsg = '';
    codeSent = false;
    resettingPassword = true;
}
function cancelReset() {
    resettingPassword = false;
}
function onLogout(_) {
    logout();
    loginError = false;
    recordingError = false;
    data = null;
}
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    if ($isDebugMode) {
        yield initializeTrials();
    }
}));
</script>

<main>
  <InactivityTimer />
  <LoaderOverlay />
  <NotificationDisplay />
  <div class="view-wrapper">
    <section>
      {#if $credentials || $isDebugMode}
        {#if data}
          <Dashboard
            on:mainTrialSelected={handleMainTrialSelected}
            on:comparisonTrialSelected={handleComparisonTrialSelected}
            on:logout={onLogout}
            on:removeComparisonTrial={removeComparisonTrial}
            {data} />
        {:else if recordingError}
          <ErrorRecordings on:logout={onLogout} />
        {/if}
      {:else if resettingPassword}
        <PasswordReset
          on:requestCode={onRequestCode}
          on:setPassword={onSetPassword}
          on:cancelReset={cancelReset}
          error={pwResetError}
          {errorMsg}
          {codeSent} />
      {:else}
        <Authentication
          on:login={onLogin}
          on:reset={startReset}
          error={loginError} />
      {/if}
    </section>
  </div>
</main>

<style>
  main {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .view-wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
  }

  section {
    width: 100vw;
    height: 100vh;
  }

</style>
