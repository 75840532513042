<script lang="ts">import PlaybackTimeline from './PlaybackTimeline.svelte';
import Dropdown from '../ui/Dropdown.svelte';
import Button from '../ui/Button.svelte';
import IconButton from '../ui/IconButton.svelte';
import { paused, currentTime, playbackRate, frame, frames, duration, fps, } from '../../../stores/playerStore';
import { last } from 'ramda';
import { trial } from '../../../stores/trial';
let selectedPlaybackRate = '1.0x';
let playbackOptions = [
    // {text: "0.1x", value: 0.1},
    { text: '0.25x', value: 0.25, selected: false },
    { text: '0.5x', value: 0.5, selected: false },
    { text: '1.0x', value: 1, selected: true },
    { text: '2.0x', value: 2, selected: false },
];
$: keyFrames = $trial.keyFramesPoints;
const pad = (input) => (input < 10 ? `0${input}` : `${input}`);
const formatTime = (sec) => {
    if (isNaN(sec))
        return '00:00:00';
    const minutes = Math.floor(sec / 60);
    const seconds = Math.floor(sec % 60);
    const decisecond = Math.floor((sec * 100) % 100);
    return `${pad(minutes)}:${pad(seconds)}:${pad(decisecond)}`;
};
function handleSelection(e) {
    playbackRate.set(e.detail.item);
    playbackOptions.forEach((option) => {
        option.selected = option.text == e.detail.item.text;
    });
    selectedPlaybackRate = e.detail.item.text;
}
function togglePaused() {
    if (currentTime.playbackFinished() && $paused) {
        currentTime.reset();
    }
    $paused = !$paused;
}
</script>

<section>
  <img class="logo" src="assets/images/logo.svg" alt="intel logo" />
  <div class="buttons">
    <IconButton
      on:click={togglePaused}
      icon={$paused ? 'play' : 'pause'}
      class="lg" />

    {#if $trial.hasKeyFrames}
      <IconButton
        on:click={() => currentTime.previousKeyFrame(keyFrames)}
        icon="previous-keyframe"
        isDisabled={$frame <= keyFrames[0]}
        class="md" />
    {/if}

    <IconButton
      on:click={() => currentTime.stepBackward()}
      icon="step-back"
      isDisabled={$frame <= 0}
      class="md" />
  </div>

  <PlaybackTimeline {keyFrames} />

  <div class="buttons">
    <IconButton
      on:click={() => currentTime.stepForward()}
      icon="step-forward"
      isDisabled={$frame >= $frames}
      class="md" />

    {#if $trial.hasKeyFrames}
      <IconButton
        on:click={() => currentTime.nextKeyFrame(keyFrames)}
        isDisabled={$frame >= last(keyFrames)}
        icon="next-keyframe"
        class="md" />
    {/if}
  </div>

  <div class="timecode">
    <div class="timecode_container">
      <div class="timecode__time">{formatTime($currentTime)}</div>
      <div>/</div>
      <div class="timecode__time">{formatTime($duration)}</div>
    </div>
    <div class="timecode__frame">
      <div class="frame">
        frame
        {String($frame).padStart(3, '0')}/{String($frames).padStart(3, '0')}
      </div>
      <div>{$fps} fps</div>
    </div>
  </div>

  <div class="playbackRate">
    <Dropdown
      items={playbackOptions}
      align={'above right'}
      on:select={handleSelection}>
      <span slot="toggle"><Button icon="down" class="icon-r">
          {selectedPlaybackRate}
        </Button></span>
    </Dropdown>
  </div>
</section>

<style>
  section {
    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .logo {
    height: 50px;
    flex-shrink: 0;
    margin: 15px 30px 15px 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 10px;
  }
  .timecode_container {
    display: flex;
    justify-content: space-between;
  }
  .timecode {
    margin-left: 10px;
    min-width: 190px;
  }

  .timecode__time {
    font-size: var(--typography-size-regular);
    color: var(--color-primary-main);
  }

  .timecode__frame {
    font-size: var(--typography-size-smaller);
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.02em;
    color: var(--color-gray-lighter);
    position: relative;
    bottom: 4px;
  }

  .playbackRate {
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
  }

</style>
