<script lang="ts">import { color } from '../../../utils/styles';
export let data;
export let backgroundColor = 'secondary-main';
export let variant = 'contained';
export let iconVisible = true;
</script>

<div class="legend-container">
  {#if iconVisible}
    <div class="legend-icon">
      <span
        class="legend-bar"
        style={`border: 1px ${variant === 'contained' ? 'solid' : 'dashed'} ${backgroundColor};`}>
        <span
          class="legend-circle"
          style={`border: 3px solid ${backgroundColor}; background: ${variant === 'contained' ? backgroundColor : color('white')};`} />
      </span>
    </div>
  {/if}

  <div class="legend-text" style="color: {backgroundColor};">{data}</div>
</div>

<style>
  .legend-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 100px;
    margin-top: 10px;
  }

  .legend-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-right: 10px;
  }

  .legend-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 4px;
    height: 0px;
  }

  .legend-circle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    box-sizing: border-box;
  }

  .legend-text {
    width: 37px;
    height: 21px;
    font-family: var(--typography-family);
    font-weight: var(--typography-weight-bold);
    font-size: var(--typography-size-small);
    line-height: 21px;
  }

</style>
