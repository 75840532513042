<script type="ts">import { last, zip } from 'ramda';
import { frame } from '../../../stores/playerStore';
import { trial, comparisonTrial } from '../../../stores/trial';
import { addSerie, addOrReplaceAnnotation, addOrReplaceSerie, Kind, } from '../../../utils/charts';
import { onMount, onDestroy } from 'svelte';
import Highcharts from 'highcharts';
;
import { color } from '../../../utils/styles';
import LegendContainer from '../ui/LegendContainer.svelte';
export let data;
export let comparisonData = null;
export let unit = '';
export let setYAxis = false;
let chart;
let chartDiv;
function getReadableData(data) {
    return zip(data.foreHindFootSway.Data, data.toeHeelSway.Data);
}
$: mainReadableData = getReadableData(data);
$: comparisonReadableData = comparisonData
    ? getReadableData(comparisonData)
    : undefined;
$: mainSerie = {
    data: mainReadableData,
    color: color('secondary-main'),
};
$: comparisonSerie = comparisonReadableData
    ? {
        data: comparisonReadableData,
        color: color('primary-main'),
    }
    : undefined;
const X_AXIS_SAMPLE = {
    min: -100,
    max: 100,
    tickInterval: 2,
    offset: -175,
    title: { text: null },
    labels: {
        enabled: false,
    },
    lineWidth: 2,
    lineColor: color('gray-lighter'),
    tickWidth: 2,
    tickLength: 15,
    tickColor: color('gray-lighter'),
    tickPosition: 'outside',
};
const Y_AXIS_SAMPLE = Object.assign(Object.assign({}, X_AXIS_SAMPLE), { gridLineColor: color('white'), minColor: color('highlight-main'), maxColor: color('accent-main') });
const config = {
    chart: {
        type: 'spline',
        margin: [75, 25, 75, 25],
    },
    title: {
        text: '',
    },
    legend: {
        backgroundColor: color('gray-extralight'),
        y: 18,
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        formatter: function () {
            return `<b>${Math.round(this.y)} ${unit}</b>`;
        },
    },
    xAxis: [X_AXIS_SAMPLE, Object.assign(Object.assign({}, X_AXIS_SAMPLE), { tickPosition: 'inside' })],
    yAxis: [Y_AXIS_SAMPLE, Object.assign(Object.assign({}, Y_AXIS_SAMPLE), { tickPosition: 'inside' })],
    plotOptions: {
        series: {
            stickyTracking: false,
            states: {
                inactive: {
                    opacity: 1,
                },
            },
            events: {
                legendItemClick: function (e) {
                    e.preventDefault();
                },
            },
        },
    },
    series: [],
    annotations: [],
};
function addCurrentFrameAnnotation(serie, id, currentFrame, trial, colorName = 'secondary-main') {
    const [x, y] = serie[currentFrame + trial.firstFrame];
    addOrReplaceAnnotation(chart, {
        id,
        draggable: '',
        shapes: [
            {
                fill: color(colorName),
                strokeWidth: 0,
                point: {
                    x,
                    xAxis: 0,
                    y,
                    yAxis: 0,
                },
                r: 8,
                type: 'circle',
            },
        ],
    });
}
function addSerieSample(currentSerie, currentTrial, index, kind) {
    addSerie({
        chart,
        name: '',
        kind,
        data: { Data: currentSerie.data, Label: '' },
        color: currentSerie.color,
        dashStyle: currentSerie.dashStyle,
        trial: currentTrial,
        index,
        setYAxis,
        showInLegend: false,
    });
}
function addOrReplaceSerieSample(id, name, data, showInLegend = true) {
    const isAddress = name === 'Address';
    addOrReplaceSerie(chart, {
        id,
        name,
        data: [isAddress ? data[0] : last(data)],
        // @ts-expect-error: Marker is allowed
        marker: {
            lineWidth: 3,
            fillColor: isAddress ? color('highlight-main') : color('white'),
            lineColor: isAddress ? color('highlight-main') : color('accent-main'),
            symbol: 'circle',
        },
        showInLegend,
    });
}
function refreshChart(mainSerie, comparisonSerie) {
    const { Main, Comparison } = Kind;
    addSerieSample(mainSerie, $trial, 0, Main);
    const trimmedData = $trial.trimFramesData(mainReadableData);
    addOrReplaceSerieSample('main-series-address', 'Address', trimmedData);
    addOrReplaceSerieSample('main-series-followthrough', 'Followthrough', trimmedData);
    if (comparisonSerie) {
        addSerieSample(comparisonSerie, $comparisonTrial, 1, Comparison);
        const trimmedData = $comparisonTrial.trimFramesData(comparisonReadableData);
        addOrReplaceSerieSample('comparison-series-address', 'Address', trimmedData, false);
        addOrReplaceSerieSample('comparison-series-followthrough', 'Followthrough', trimmedData, false);
    }
}
$: {
    if (chart) {
        refreshChart(mainSerie, comparisonSerie);
    }
}
$: {
    // We need this to change when mainSerie changes, so we added it in the if.
    if (chart && mainSerie) {
        addCurrentFrameAnnotation(mainReadableData, 'mainCurrentFrame', $frame, $trial);
    }
    if (chart && comparisonSerie) {
        addCurrentFrameAnnotation(comparisonReadableData, 'comparisonCurrentFrame', $frame, $comparisonTrial, 'primary-main');
    }
}
onMount(() => {
    chart = Highcharts.chart(chartDiv, config);
});
onDestroy(() => {
    chart.destroy();
});
</script>

<div class="container">
  <div class="coordinate-plane" bind:this={chartDiv} />

  {#if !!$comparisonTrial}
    <div style="margin-bottom: -42px; display:flex; justify-content: center;">
      <LegendContainer
        firstTrialName={$trial.displayName}
        secondTrialName={$comparisonTrial.displayName} />
    </div>
  {/if}
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .coordinate-plane {
    width: 400px;
    height: 500px;
    margin: 1.5em 1em;
  }

</style>
